'use strict';

const nav = $('.header .nav'),
      navWhiteClass = 'nav--white';

const slider = () => {

  const sliderSelector = '.facts__slider',
        sliderListSelector = '.js-slider',
        sliderList = $(sliderListSelector),
        btnPrevSelector = '.js-btn-prev',
        btnNextSelector = '.js-btn-next';
  
  function onClickBtnArrow(btnSelector, method) {
    const btn = $(btnSelector);

    btn.on('click', function() {
      const _slider = $(this).closest(sliderSelector).find(sliderListSelector);
      _slider.slick(method);
    });
  };

  function initSlider() {
    
    if (!sliderList.hasClass('slick-initialized')){
      sliderList.slick({
        adaptiveHeight: false,
        arrows: false,
        dots: false,
        lazyLoad: 'ondemand',
        speed: 700,
        slidesToShow: 1,
      });
    }
  };

  initSlider();
  onClickBtnArrow(btnNextSelector, 'slickNext');
  onClickBtnArrow(btnPrevSelector, 'slickPrev');
};

const quizDataObject = {
  initQuestion: 'gifts',

  questions: {
    gifts: [
      'Какие подарки вы дарите чаще всего?',
      {
        color: '#ED553B',
        octagon: '/001/01.png',
        heptagon: '/001/02.png',
        hexagon: '/001/03.png'
      },
      [
        ['Смотрю wish list человека, либо спрашиваю его напрямую о том, что бы он хотел', 2],
        ['Дарю сертификаты, считаю, что подарить эмоции лучше, чем что-то материальное', 1],
        ['Не задумываюсь, что дарить. Главное – сам акт дарения.', 0]
      ],
      'wrapper'
    ],
    wrapper: [
      'Если вы дарите что-то, что хотите красиво упаковать, как именно вы поступаете?',
      {
        color: '#49BF7A',
        octagon: '/002/01.png',
        heptagon: '/002/02.png',
        hexagon: '/002/03.png'
      },
      [
        ['Не упаковываю, праздничные обертки не нужны', 2, '', 2],
        ['Упаковываю сам(а) в коричневую бумагу', 1, '', 1, '', 'utilization_3'],
        ['Упаковываю в торговых центрах сразу после покупки', 0, '', 1, '', 'utilization_3']
      ],
      'fir_usage',
      'Каждый год в середине декабря появляется главная ёлка страны, но для многих людей более важной кажется своя ель, "домашняя".'
    ],
    fir_usage: [
      'Украшаете ли вы ёлку к празднику?',
      {
        color: '#20639B',
        octagon: '/003/01.png',
        heptagon: '/003/02.png',
        hexagon: '/003/03.png'
      },
      [
        ['Да', 0, 'fir'],
        ['Нет', 4, 'food', 4, 'Подарки есть, ёлка не нужна. В конце декабря наступает пора длинных очередей в гипермаркетах. Да-да, в этом году тоже вряд ли что-то изменится, поэтому стоит заранее спланировать свои покупки. Или нет?']
      ]
    ],
    fir: [
      'Какую ёлку вы используете?',
      {
        color: '#F6D55C',
        octagon: '/004/01.png',
        heptagon: '/004/02.png',
        hexagon: '/004/03.png'
      },
      [
        ['Ёлку во дворе', 2, '', 2],
        ['Покупаю живую ёлку домой', 1, '', 1, '', 'utilization_2'],
        ['У меня уже есть искуственная ёлка', 0, '', 2, 'Знаете ли вы, что искусственную ель нужно использовать не менее 20 лет, чтобы сравнять свой углеродный след с тем, как если бы вы использовали живую ель?']
      ],
      'toys_usage'
    ],
    toys_usage: [
      'Что насчет украшений?',
      {
        color: '#ED553B',
        octagon: '/005/01.png',
        heptagon: '/005/02.png',
        hexagon: '/005/03.png'
      },
      [
        ['Из года в год использую одни и те же украшения', 2, '', 2, 'Подарки есть, ёлка есть. В конце декабря наступает пора длинных очередей в гипермаркетах. Да-да, в этом году тоже вряд ли что-то изменится, поэтому стоит заранее спланировать свои покупки. Или нет?'],
        ['Мастерю украшения своими руками', 1, 'toys'],
        ['Каждый год покупаю украшения', 0, '', 2, 'Подарки есть, ёлка есть. В конце декабря наступает пора длинных очередей в гипермаркетах. Да-да, в этом году тоже вряд ли что-то изменится, поэтому стоит заранее спланировать свои покупки. Или нет?']
      ],
      'food'
    ],
    toys: [
      'Из чего вы делаете украшения?',
      {
        color: '#49BF7A',
        octagon: '/006/01.png',
        heptagon: '/006/02.png',
        hexagon: '/006/03.png'
      },
      [
        ['Из того, что могу найти дома', 1],
        ['Покупаю наборы для изготовления различных поделок', 0]
      ],
      'food',
      'Подарки есть, ёлка есть. В конце декабря наступает пора длинных очередей в гипермаркетах. Да-да, в этом году тоже вряд ли что-то изменится, поэтому стоит заранее спланировать свои покупки. Или нет?'
    ],
    food: [
      'Где и какие продукты вы покупаете чаще всего?',
      {
        color: '#20639B',
        octagon: '/007/01.png',
        heptagon: '/007/02.png',
        hexagon: '/007/03.png'
      },
      [
        ['Покупаю продукты  местного производства на рынке', 2],
        ['Покупаю продукты  местного производства в сетевых магазинах', 1],
        ['Не смотрю на страну производителя, покупаю всё в сетевых магазинах', 0, '', 1, 'Конечно, лучше покупать продукты местного производства, ведь на их транспортировку было потрачено меньше топлива.']
      ],
      'after_payment'
    ],
    after_payment: [
      'После оплаты на кассе вы...',
      {
        color: '#F6D55C',
        octagon: '/008/01.png',
        heptagon: '/008/02.png',
        hexagon: '/008/03.png'
      },
      [
        ['Моя сумка из плотного пластика выдержит всё', 2],
        ['У меня для этого есть шоппер из натуральных материалов', 1, '', 1, 'Знали ли вы, что у некоторых людей уже начали появляться шопперы с шопперами вместо пакетов с пакетами? Кажется, что это экологично, но на самом деле для производства одного шоппера из хлопка тратится большое количество воды и пестицидов. Некоторые полагают, что самый экологичный вариант для шоппинга – большие сумки из плотного пластика.'],
        ['Упакую все в десять пластиковых или бумажных пакетов, купленных прямо на кассе', 0, '', 1, 'Возможно, вы забыли свои эко-пакеты, возможно, вам всё равно, что там происходит с миром вокруг, мы не знаем. Можем лишь рассказать, что некоторые исследователи считают, что лучший способ повторного использования пластиковых пакетов из магазина – использование их в качестве мусорных пакетов. Кроме того, в них должно быть помещено что-то тяжелое, чтобы пакет не улетел со свалки перерабатывающего завода.']
      ],
      'festive_table'
    ],
    festive_table: [
      'Представьте свой праздничный стол. Сколько еды вы готовите в новогоднюю ночь?',
      {
        color: '#ed553b',
        octagon: '/009/01.png',
        heptagon: '/009/02.png',
        hexagon: '/009/03.png'
      },
      [
        ['Я опытный, поэтому готовлю только на один вечер', 2, 'tableware', 2, 'Некоторые люди всерьез заботятся о сервировке стола, в то время как для остальных данный вопрос не представляет никакой важности.'],
        ['Так получается, что доедаю еще несколько дней, подключаю к процессу доедания всех знакомых', 1, 'tableware', 2, 'Некоторые люди всерьез заботятся о сервировке стола, в то время как для остальных данный вопрос не представляет никакой важности.'],
        ['Выкидываю недоеденное первого числа, потому что успевает испортиться', 0, 'expired_products']
      ],
      ''
    ],
    expired_products: [
      'Куда вы выбрасываете просроченные продукты?',
      {
        color: '#49BF7A',
        octagon: '/010/01.png',
        heptagon: '/010/02.png',
        hexagon: '/010/03.png'
      },
      [
        ['Компостирую; отправляю в измельчитель в раковине; сушу/замораживаю/закапываю', 1],
        ['Выбрасываю все в мусорный контейнер', 0]
      ],
      'tableware',
      'Некоторые люди всерьез заботятся о сервировке стола, в то время как для остальных данный вопрос не представляет никакой важности.'
    ],
    tableware: [
      'Какую посуду вы обычно используете на Новый Год?',
      {
        color: '#20639B',
        octagon: '/011/01.png',
        heptagon: '/011/02.png',
        hexagon: '/011/03.png'
      },
      [
        ['Праздную дома, поэтому пользуюсь домашней', 2, '', 2],
        ['Арендую посуду', 1, '', 2],
        ['Покупаю одноразовую, чтобы потом не мыть', 0, '', 1, '', 'utilization_1']
      ],
      'wish',
      'Хорошо. Итак, ёлка есть, накрытый стол есть, подарки есть.'
    ],
    wish: [
      '3... 2... 1... С Новым годом! Пора загадывать желание! Лучший способ это...',
      {
        color: '#F6D55C;',
        octagon: '/012/01.png',
        heptagon: '/012/02.png',
        hexagon: '/012/03.png'
      },
      [
        ['Загадываю про себя, чтобы никто не узнал', 2],
        ['Пишу на бумажке под бой курантов, потом сжигаю бумажку и выпиваю ее вместе с жидкостью в бокале', 1],
        ['Запускаю воздушный фонарик как это делают в ряде азиатских стран', 0, '', 1, 'Фонарики могут удаляться на расстояние до 6 километров от места запуска и летать до получаса, представляя собой реальную опасность для зданий, растительности и людей. Так, в июне 2013 года в заповеднике "Столбы" в Красноярске из-за запущенных туристами небесных фонариков выгорело почти 3,5 гектара леса.']
      ],
      'fireworks'
    ],
    fireworks: [
      'Один из атрибутов хорошего Нового Года – фейерверк! Или...',
      {
        color: '#ed553b',
        octagon: '/013/01.png',
        heptagon: '/013/02.png',
        hexagon: '/013/03.png'
      },
      [
        ['Я не люблю фейерверки, поэтому не запускаю и не смотрю', 2],
        ['Сам(а) я не запускаю их, но люблю наблюдать', 1],
        ['Да, кончено, каждый год я или моя семья запускаем один фейерверк', 0]
      ],
      '',
      'Действительно, фейерверки способствуют производству углекислого или парникового газа, а также мелкодисперсной пыли. Вы, наверное, замечали и другие негативные эффекты фейерверков: громкие залпы вызывают страх и панику у птиц и животных, а после запусков от фейерверков на улицах остается много мусора.'
    ],

    utilization_1: [
      'Как вы утилизируете одноразовую посуду после праздника?',
      {
        color: '#49BF7A',
        octagon: '/014/01.png',
        heptagon: '/014/02.png',
        hexagon: '/014/03.png'
      },
      [
        ['Пластиковую разберу на подходящую для переработки и неподходящую. Сдам в переработку, что смогу', 2],
        ['Сдам всю одноразовую посуду в переработку', 1],
        ['Скину всю одноразовую посуду с остатками еды в большой мешок и выкину в контейнер', 0]
      ]
    ],
    utilization_2: [
      'Праздник прошел, ёлку пора убирать. Каким способом вы ее уберете?',
      {
        color: '#20639B',
        octagon: '/015/01.png',
        heptagon: '/015/02.png',
        hexagon: '/015/03.png'
      },
      [
        ['Сдам на специальную точку сбора ёлок', 2],
        ['Выкину в мусорный контейнер', 1],
        ['Я не выкидываю ёлку, она стоит у меня до марта, а потом исчезает', 0]
      ]
    ],
    utilization_3: [
      'После распаковки подарков, куда вы денете упаковочные материалы?',
      {
        color: '#F6D55C',
        octagon: '/016/01.png',
        heptagon: '/016/02.png',
        hexagon: '/016/03.png'
      },
      [
        ['Всю коричневую бумагу я сдам в переработку, остальную бумагу проверю на пригодность к переработке вручную', 2],
        ['Всё выкину в пакет с вторсырьем', 1, '', 1, 'Не вся упаковочная бумага подлежит переработке. К примеру, сдавать упаковочную бумагу нельзя, если она содержит блестки, фольгу, искусственную текстуру, липкие подарочные этикетки или пластик.'],
        ['Выкину в пакет со смешанными отходами', 0]
      ]
    ]
  },

  results: {
    bad: {
      min_score: 0,
      title: 'Поздравляем! Вы – лучший друг метана и углекислого газа!',
      desc: 'Кажется, самое время задуматься о личном вкладе в загрязнение планеты. До Нового Года осталось совсем немного, еще можно что-то сделать!'
    },
    poor: {
      min_score: 6,
      title: 'Хмм... Вы на верном пути!',
      desc: 'Возможно, некоторые из ваших привычек экологичны, но далеко не все. Мы советуем вам изучить тему экологичности во время праздников.'
    },
    good: {
      min_score: 11,
      title: 'Пять мину-у-...',
      desc: 'Большая часть ваших привычек экологична, но буквально пяти минут хватит на то, чтобы вы могли узнать, как сделать празднование Нового Года еще более устойчивым.'
    },
    great: {
      min_score: 18,
      title: 'Расступитесь, идет бог(иня) экологичных праздников!',
      desc: 'Мы очень рады, что вы отмечаете Новый год подобным образом. Не стесняйтесь делиться своими эко-привычками с семьей, друзьями и коллегами :)'
    }
  }
};

class Answer{

  constructor(text, points, next, coef, hint, extra){
    this.text = text;
    this.points = points;
    this.next = next;
    this.coef = coef;
    this.hint = hint;
    this.extra = extra;
  }

}

class Question{

  constructor(text, decor, answers, next = '', hint = ''){
    this.text = text;
    this.decor = decor;
    this.next = next;
    this.hint = hint;

    this.answers = [];
    answers.forEach((value) => {
      let _next = '',
          _coef = 1,
          _hint = '',
          _extra = '';
      if (value.hasOwnProperty(2)){
        _next = value[2];
      }
      if (value.hasOwnProperty(3)){
        _coef = value[3];
      }
      if (value.hasOwnProperty(4)){
        _hint = value[4];
      }
      if (value.hasOwnProperty(5)){
        _extra = value[5];
      }
      this.answers.push(
        new Answer(value[0], value[1], _next, _coef, _hint, _extra)
      );
    });
  }

}

class Quiz{

  constructor(data = {}, wrapper){
    this.currentQuestion = null;
    this.score = null;
    this._pbHumanPos = null;
    this._pbBinPos = null;
    this.extraQuestionsKeys = null;

    this._bindData(data);
    this._bindJQueryObjects(wrapper);
    this._bindEventHandlers();
  }

  _bindData(data){
    this.initQuestion = data.initQuestion;
    this.totalQuestions = Object.keys(data.questions).length;
    this.results = data.results;

    for (const key in data.questions){
      let _next = '',
          _hint = '';
      if (data.questions[key].hasOwnProperty(3)){
        _next = data.questions[key][3];
      }
      if (data.questions[key].hasOwnProperty(4)){
        _hint = data.questions[key][4];
      }

      this[`q_${key}`] = new Question(
        data.questions[key][0],
        data.questions[key][1],
        data.questions[key][2],
        _next,
        _hint
      )
    }
  }

  _bindJQueryObjects(wrapper){
    this.$wrapper = wrapper;

    this.$introPage = this.$wrapper.find('.ny-game__intro');
    this.$quizPage = this.$wrapper.find('.ny-game__questions');
    this.$resultPage = this.$wrapper.find('.ny-game__result');

    this.$questionText = this.$quizPage.find('.question__title');
    this.$questionBg = this.$quizPage.find('.ny-game__bg');
    this.$questionOctagon = this.$quizPage.find('.image-clip--octagon img');
    this.$questionHeptagon = this.$quizPage.find('.image-clip--heptagon img');
    this.$questionHexagon = this.$quizPage.find('.image-clip--hexagon img');
    this.$questionPBHuman = this.$quizPage.find('.progress-bar__current');
    this.$questionPBBin = this.$quizPage.find('.progress-bar__end');

    this.$answerBlank = this.$quizPage.find('.js-answers-blank');
    this.$answersWrapper = this.$quizPage.find('.answers__list');

    this.$hintPopup = this.$quizPage.find('.popup-answer');
    this.$hintPopupText = this.$hintPopup.find('.popup-answer__desc');
    this.$hintPopupBtn = this.$hintPopup.find('.js-game-continue');

    this.$finalScore = this.$resultPage.find('.js-final-score');
    this.$resultTitle = this.$resultPage.find('.result__title');
    this.$resultDesc = this.$resultPage.find('.result__text');

    this.$share = this.$resultPage.find('.share'),
    this.$tgShare = this.$share.find('.tg');
  }

  _bindEventHandlers(){

    this.$wrapper.on('click', '.js-game-start', (e) => {
      const target = $(e.target);
      target.prop('disabled', true);
      this.$introPage.fadeOut(300, () => {
        target.prop('disabled', false);
        this.start();
        this.$quizPage.fadeIn(300);
      });
    });

    this.$wrapper.on('click', '.js-game-again', (e) => {
      const target = $(e.target);
      target.prop('disabled', true);
      this.$resultPage.fadeOut(300, () => {
        target.prop('disabled', false);
        this.start();
        this.$quizPage.fadeIn(300);
      });
    });

    this.$wrapper.on('click', '.js-game-continue', (e) => {
      const target = $(e.target);
      target.prop('disabled', true);
      this.hideHintPopup();
      
      if (this.currentQuestion){
        this.next();
      } else {
        this.result();
      }
    });

    this.$wrapper.on('click', '.answers__item:not(.disabled)', (e) => {
      this.$answersWrapper.find('.answers__item').addClass('disabled');

      const chosenAnswer = this.currentQuestion.answers[
        $(e.target).data('index')
      ];
      this.score += chosenAnswer.points;
      this.pbHumanPos += parseInt(100/this.totalQuestions);
      this.$questionPBHuman.toggleClass('step');
      if (chosenAnswer.coef > 1){
        this.pbBinPos += parseInt(100/this.totalQuestions * (chosenAnswer.coef - 1));
      }
      if (chosenAnswer.extra){
        this.extraQuestionsKeys.push(chosenAnswer.extra);
      }

      let hintText = chosenAnswer.hint;
      if (!hintText){
        hintText = this.currentQuestion.hint;
      }

      let nextQuestionKey = chosenAnswer.next;
      if (!nextQuestionKey){
        nextQuestionKey = this.currentQuestion.next;
      }
      if (!nextQuestionKey){
        nextQuestionKey = this.extraQuestionsKeys.shift() || '';
      }
      if (nextQuestionKey){
        this.currentQuestion = this.getQuestion(nextQuestionKey);
      } else {
        this.currentQuestion = null;
      }

      if (hintText){
        this.showHintPopup(hintText);
      } else {
        if (this.currentQuestion){
          this.next();
        } else {
          this.result();
        }
      }
    });
  }

  _percentCoercion(value){
    if (value > 100){
      value = 100;
    } else if (value < 0){
      value = 0;
    }
    
    return value;
  }

  set pbHumanPos(value){
    this._pbHumanPos = this._percentCoercion(value);
    this.$questionPBHuman.css('margin-left', `${this.pbHumanPos}%`);
  }

  get pbHumanPos(){
    return this._pbHumanPos;
  }

  set pbBinPos(value){
    this._pbBinPos = this._percentCoercion(value);
    this.$questionPBBin.css('margin-right', `${this.pbBinPos}%`);
  }

  get pbBinPos(){
    return this._pbBinPos;
  }

  showHintPopup(text){
    this.$hintPopupText.text(text);
    this.$hintPopup.fadeIn(300);
  }

  hideHintPopup(){
    this.$hintPopup.fadeOut(300, () => {
      this.$hintPopupText.text('');
      this.$hintPopupBtn.prop('disabled', false);
    });
  }

  getQuestion(key){
    return this[`q_${key}`];
  }

  getInitQuestion(){
    return this.getQuestion(this.initQuestion);
  }

  start(){
    nav.addClass(navWhiteClass);
    this.$questionPBHuman.removeClass('step');

    this.currentQuestion = this.getInitQuestion();
    this.score = 0;
    this.pbHumanPos = 0;
    this.pbBinPos = 0;
    this.extraQuestionsKeys = [];
    this.next();
  }

  next(){
    this.$quizPage.fadeOut(300, () => {
      this.$questionText.text(this.currentQuestion.text);
      let buffer = $();
      this.currentQuestion.answers.forEach((a, i) => {
        const item = this.$answerBlank.clone();
        item.text(a.text)
            .data('index', i)
            .removeClass('hidden js-answers-blank');
        buffer = buffer.add(item);
      });
      this.$answersWrapper.html(buffer);

      this.$questionBg.css('background-color', this.currentQuestion.decor.color);
      this.$questionOctagon.attr('src', `/static/img/content/game/clip/${this.currentQuestion.decor.octagon}`);
      this.$questionHeptagon.attr('src', `/static/img/content/game/clip/${this.currentQuestion.decor.heptagon}`);
      this.$questionHexagon.attr('src', `/static/img/content/game/clip/${this.currentQuestion.decor.hexagon}`);

      this.$quizPage.fadeIn(300);
    });
  }

  result(){
    this.$quizPage.fadeOut(300, () => {
      slider();
      nav.removeClass(navWhiteClass);

      let resultKey = '';
      for (const key in this.results){
        if (!resultKey || this.score > this.results[key].min_score){
          resultKey = key;
        }
      }
      
      this.$finalScore.text(this.score);
      this.$resultTitle.text(this.results[resultKey].title);
      this.$resultDesc.text(this.results[resultKey].desc);

      this.$resultPage.fadeIn(300);

      this.share();
    });
  }

  share() {
    if (this.score > -1 && this.score < 23){
      const url = `https://beresponsible.nat-geo.ru/ny-game/results/${this.score}/`,
            text = 'Тест от National Geographic и Всероссийского общества охраны природы поможет вам узнать, насколько ваши праздничные привычки экологичны';
      this.$tgShare.attr('href', `tg://msg_url?url=${encodeURIComponent(url)}` + `&text=${encodeURIComponent(text)}`);
      this.$share.socialLikes({url: url, counters: false});
    }
  }
}

$(function(){
  const gameWrapper = $('.js-game-wrapper');
  if (gameWrapper.length > 0){
    new Quiz(quizDataObject, gameWrapper);
  }
})
