'use strict';

const activeClass = 'active',
      inActiveClass = 'inactive',
      showClass = 'show',
      hideClass = 'hide',
      body = 'body',
      menu = '.menu',
      btnCloseMenu = $(menu).find('.btn-close');

function setAttr(elem, attr) {
  $(elem).each(function (i, it) {
    $(it).attr(attr, i);
  });
};

const burgerMenu = () => {

  function bindMenu(trigger, menu) {
    $(trigger).on('click', function() {
      $(menu).addClass(showClass);
    });

    btnCloseMenu.on('click', function() {
      $(menu).removeClass(showClass);
    });
  }

  bindMenu('.btn-menu', menu);
};

const sideScroll = () => {
  const pageArticle = $('.page-article');

  if(pageArticle.length > 0) {
    const scroll = skrollr.init({
      render: function(data) {
        if(data.curTop > 0 && $(menu).hasClass(showClass)) {
          btnCloseMenu.click();
        }
      },
    });

    if(window.matchMedia('(max-width: 1169px)').matches) {
      scroll.destroy();
    }
  }
};

const popups = () => {

  const btnInfoGraph = '.infograp__btn-item',
        btnRules = '.btn-rules',
        popupInfoGraph = '.popup-infograp',
        popupRules = '.popup-rules',
        popupContent = '.popup-infograp .popup__content',
        btnClose = '.btn-close';

  function bindPopup(trigger, popup) {

    $(document).on('click', trigger, function() {
      showContent($(this), popupContent, 'data-popup');
      $(popup).fadeIn();
      $(body).addClass(inActiveClass);
    });
  
    $(document).on('click', popup, function(e) {
      const classPopup = popup.split('.')[1],
            btnClass = $(this).find(btnClose).attr('class');
  
      if($(e.target).hasClass(classPopup) || $(e.target).hasClass(btnClass)) {
        $(body).removeClass(inActiveClass);
        $(popup).fadeOut();
      }
    });

    function showContent(trigger, contentElem, dataAttr) {
      let _id = trigger.attr(dataAttr),
          _content = $(`${contentElem}[${dataAttr}="${_id}"]`);

      $(`${contentElem}.${showClass}`).removeClass(showClass);
      _content.addClass(showClass);
    };
  };

  setAttr(btnInfoGraph, 'data-popup');
  setAttr(popupContent, 'data-popup');
  bindPopup(btnInfoGraph, popupInfoGraph);
  bindPopup(btnRules, popupRules);
};

const animationPage = () => {
  
  AOS.init({
    startEvent: 'DOMContentLoaded',
    easing: 'ease-in-out',
    duration: 700,
    once: true,
  });
};

const share = () => {

  $('.share-main').socialLikes({
    counters: false,
  });
};

const quiz = () => {
  
  const polluters = [
    {
      icon: 'cup',
      title: 'Пластиковый стаканчик',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 4,
    },
    {
      icon: 'tin-bank',
      title: 'Жестяная банка',
      answers: [
          '3-12 <br>месяцев',
          '10 лет',
          'До 90 лет',
          'До 1000 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 2,
    },
    {
      icon: 'al-bank',
      title: 'Алюминиевая банка',
      answers: [
          '3-12 <br>месяцев',
          '10 лет',
          'До 90 лет',
          'До 1000 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 3,
    },
    {
      icon: 'napkin',
      title: 'Влажная салфетка',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 3,
    },
    {
      icon: 'papper',
      title: 'Бумага и картон',
      answers: [
          '3-12 <br>месяцев',
          '10 лет',
          'До 90 лет',
          'До 1000 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 0,
    },
    {
      icon: 'bottle',
      title: 'Стеклянная тара',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 0,
    },
    {
      icon: 'swab',
      title: 'Вата из ватных палочек',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 2,
    },
    {
      icon: 'diaper',
      title: 'Подгузник',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 4,
    },
    {
      icon: 'butt',
      title: 'Окурок',
      answers: [
          'Больше 1000 лет',
          '5-15 лет',
          'До 5 <br>месяцев',
          'Около 100 лет',
          'Около<br>500 лет'
      ],
      correctAnswer: 1,
    },
    {
      icon: 'plastic',
      title: 'Пластиковая бутылка с крышкой',
      answers: [
          '500 и 1000 лет<br>соответственно',
          'Около 200 лет',
          '50 и 100 лет<br>соответственно',
          '200 и 500 лет<br>соответственно'
      ],
      correctAnswer: 0,
    },
  ];

  const resultsData = {
    bad: {
      title: 'Не самый лучший результат...',
      text: 'Ваших знаний о мусоре недостаточно, чтобы помнить, как долго он будет представлять опасность для живой природы и наших потомков. Не забывайте, насколько это важно. Изучите таблицу ниже, чтобы исправить эту ситуацию. И обязательно делитесь своими знаниями со всеми.',
    },
    normal: {
      title: 'Хороший результат',
      text: 'Ваших знаний о мусоре хватает, чтобы помнить, как долго он будет представлять опасность для живой природы и наших потомков. Но в этих знаниях ещё есть пробелы. Изучите таблицу ниже, чтобы заполнить их. И обязательно делитесь своими знаниями со всеми.',
    },
    good: {
      title: 'Отличный результат!',
      text: 'Вы знаете, сколько с нашими отходами придётся справляться природе. Всё это время они будут представлять опасность для живой природы и наших потомков. Помните эту информацию всегда и старайтесь донести до других.',
    },
  };

  const flow = [
    init,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderNextPolluter,
    renderResult,
    reset
  ];

  let currentStep = -1,
      polluterPosition,
      currentPolluter,
      correctAnswer,
      currentAnswer,
      correctAnswers;

  const sectionImg = $('.section__img'),
        quiz = $('.quiz'),
        quizIntro = quiz.find('.quiz__intro'),
        quizContent = quiz.find('.quiz__content'),
        quizPolluters = quizContent.find('.quiz__polluters'),
        quizResult = quiz.find('.quiz__result'),
        quizFooter = quiz.find('.quiz__footer'),
        questionAnswers = quizContent.find('.answers'),
        quizPopup = quizContent.find('.popup-answer'),
        correctAnswersCounter = quizContent.find('.score__count').find('span'),
        resultNum = quizResult.find('.score__count').find('span'),
        resultTitle = quizResult.find('.result__title'),
        resultText = quizResult.find('.result__text'),
        btnStartClass = '.btn-start',
        btnNextClass = '.btn-next',
        btnContClass = '.btn-continue',
        btnAgainClass = '.btn-again',
        shareBlock = quizResult.find('.share'),
        shareTg = shareBlock.find('.telegram');

  function renderPolluters() {
      let pollutersTemplate = '';
      
      for (const polluter in polluters) {
        polluterPosition = +polluter+1;
        pollutersTemplate += `
          <div class="quiz__polluters-item" data-position="${polluterPosition}">
              <div class="quiz__polluters-img quiz__polluters-img--${polluters[polluter].icon}"></div>
              <div class="quiz__polluters-body">
                <p class="quiz__polluters-title">${polluters[polluter].title}</p>
              </div>
          </div>
        `;
      }

      quizPolluters.append(pollutersTemplate);
  };

  function flawCallNext(){
    flow[++currentStep]();
  };

  function init(){
    currentPolluter = 0;
    correctAnswers = 0;

    sectionImg.hide();
    quizIntro.hide();
    quizResult.hide();
    quizContent.fadeIn();
    quizFooter.css('padding-top', '40px');
    
    renderPolluters()

    flawCallNext();
  };

  function renderNextPolluter(){
    $("[data-position='"+currentStep+"']").addClass("current");

    if (currentStep == 1) {
      $("[data-position='1']").addClass("current");
    } else {
      $("[data-position='"+(currentStep-1)+"']").removeClass("current");
      $("[data-position='"+(currentStep-1)+"']").addClass("passed");
    }

    quizPopup.fadeOut();
    quizPopup.html('')
    questionAnswers.html('');

    let answersTeamplate = '';

    switch (currentPolluter) {
      case 0:
      case 3:
      case 5:
      case 6:
      case 7:
      case 8:
        answersTeamplate = `
        <div class="answers__list">
            <div class="answers__col answers__col--xl">
                <p class="answers__item" data-answer="0">${polluters[currentPolluter].answers[0]}</p>
            </div>
            <div class="answers__col answers__col--md">
                <p class="answers__item answers__item--md" data-answer="1">${polluters[currentPolluter].answers[1]}</p>
                <p class="answers__item answers__item--xs" data-answer="2">${polluters[currentPolluter].answers[2]}</p>
                <p class="answers__item" data-answer="3">${polluters[currentPolluter].answers[3]}</p>
            </div>
            <div class="answers__col answers__col--xs">
                <p class="answers__item" data-answer="4">${polluters[currentPolluter].answers[4]}</p>
            </div>
        </div>
        `;
      break;
      case 1:
      case 2:
      case 4:
        answersTeamplate = `
        <div class="answers__list">
          <div class="answers__col answers__col--md">
            <p class="answers__item answers__item--xxs" data-answer="0">${polluters[currentPolluter].answers[0]}</p>
            <p class="answers__item answers__item--mmd" data-answer="1">${polluters[currentPolluter].answers[1]}</p>
            <p class="answers__item" data-answer="2">${polluters[currentPolluter].answers[2]}</p>
          </div>
          <div class="answers__col answers__col--xl">
            <p class="answers__item" data-answer="3">${polluters[currentPolluter].answers[3]}</p>
          </div>
          <div class="answers__col answers__col--xs">
            <p class="answers__item" data-answer="4">${polluters[currentPolluter].answers[4]}</p>
          </div>
        </div>
        `;
      break;
      case 9:
        answersTeamplate = `
        <div class="answers__list answers__list--xs">
          <div class="answers__col answers__col--xl">
              <p class="answers__item" data-answer="0">${polluters[currentPolluter].answers[0]}</p>
          </div>
          <div class="answers__col answers__col--md">
              <p class="answers__item" data-answer="1">${polluters[currentPolluter].answers[1]}</p>
              <p class="answers__item" data-answer="2">${polluters[currentPolluter].answers[2]}</p>
          </div>
          <div class="answers__col answers__col--xs">
              <p class="answers__item" data-answer="3">${polluters[currentPolluter].answers[3]}</p>
          </div>
        </div>
        `;
      break;
    }
    questionAnswers.append(answersTeamplate);

    correctAnswer = polluters[currentPolluter].correctAnswer;
  };

  quiz.on('click', '.answers__item', function() {
    
    const _this = $(this),
          _answers = _this.parent().find(".answers__item");

    _answers.removeClass('selected');
    _this.addClass('selected');

    currentAnswer  = $('.answers__item.selected').attr("data-answer");

    let popupAnswerTemplate = '';
    let polluterTitle = polluters[currentPolluter].title;
    let correctAnswersText = polluters[currentPolluter].answers[correctAnswer].toLowerCase().replace(/(?:&nbsp;|<br>)/g,'&nbsp;');
    if (currentAnswer == correctAnswer) {
      correctAnswers += 1;
      popupAnswerTemplate = `
      <div class="popup__content">
          <div class="popup-answer__img"><img class="cover" src="/static/img/content/quiz/icon_012.svg" alt="" /></div>
          <p class="popup-answer__title title">Вы правы!</p>
          <p class="popup-answer__desc text">${polluterTitle} разлагается <br>${correctAnswersText}.</p>
          <button class="btn btn-continue">продолжить</button>
      </div>
      `;
      correctAnswersCounter.html('');
      correctAnswersCounter.append(correctAnswers);
    } else {
      popupAnswerTemplate = `
      <div class="popup__content">
          <div class="popup-answer__img"><img class="cover" src="/static/img/content/quiz/icon_011.svg" alt="" /></div>
          <p class="popup-answer__title title">Вы ошиблись!</p>
          <p class="popup-answer__desc text">${polluterTitle} разлагается <br>${correctAnswersText}</p>
          <button class="btn btn-continue">продолжить</button>
      </div>
      `;
    }
    quizPopup.append(popupAnswerTemplate);
    quizPopup.fadeIn();

    currentPolluter++;
  });

  function renderResult(){
    gtag('event', 'click', {
      'event_category': 'test',
      'event_label': 'finished',
      'value': 'end_test'
    });

    resultNum.text(correctAnswers);
    quizContent.hide();
    quizResult.fadeIn();

    let url;

    switch (correctAnswers) {
      case 1:
        resultTitle.text(resultsData.bad.title);
        resultText.text(resultsData.bad.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-1/';
        break;
      case 2:
        resultTitle.text(resultsData.bad.title);
        resultText.text(resultsData.bad.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-2/';
        break;
      case 3:
        resultTitle.text(resultsData.bad.title);
        resultText.text(resultsData.bad.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-3/';
        break;
      case 4:
        resultTitle.text(resultsData.bad.title);
        resultText.text(resultsData.bad.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-4/';
        break;
      case 5:
        resultTitle.text(resultsData.normal.title);
        resultText.text(resultsData.normal.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-5/';
        break;
      case 6:
        resultTitle.text(resultsData.normal.title);
        resultText.text(resultsData.normal.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-6/';
        break;
      case 7:
        resultTitle.text(resultsData.normal.title);
        resultText.text(resultsData.normal.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-7/';
        break;
      case 8:
        resultTitle.text(resultsData.normal.title);
        resultText.text(resultsData.normal.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-8/';
        break;
      case 9:
        resultTitle.text(resultsData.good.title);
        resultText.text(resultsData.good.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-9/';
        break;
      case 10:
        resultTitle.text(resultsData.good.title);
        resultText.text(resultsData.good.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-10/';
        break;
      default:
        resultTitle.text(resultsData.bad.title);
        resultText.text(resultsData.bad.text);
        url = 'http://beresponsible.nat-geo.ru/quiz-results/results-0/';
        break;
    }

    shareTg.attr('href', `tg://msg_url?url=${url}&amp;text=Россия без мусора`);
    shareBlock.socialLikes({
        url: url,
        counters: false,
    });
  };

  function reset(){
    currentStep = -1;

    correctAnswers = 0;
    correctAnswersCounter.html('');
    correctAnswersCounter.append(correctAnswers);

    resultTitle.text('');
    resultText.text('');
    
    quizPolluters.html('');
    flawCallNext();
  };

  quiz.on(
    'click',
    [
      btnStartClass,
      btnContClass,
      btnNextClass,
      btnAgainClass
    ].join(','),
    function(){
      flawCallNext();
    }
  );
};

const headerFixed = () => {

  function scroll() {
    let scrollTop = $(window).scrollTop(),
        sectionTop = $('.article').outerHeight();

    if(scrollTop >= sectionTop) {
      $('.header').addClass('fixed');
    } else {
      $('.header').removeClass('fixed');
    }
  }

  scroll();
  $(window).on('scroll', scroll);
};

const eventsGA = () => {
  $('.logo').on('click', function() {
    gtag('event', 'click', {
      'event_category': 'click_logo',
      'event_label': 'click_logo_header',
      'value': 'click_logo_header'
    });
  });

  $('.btn-start').on('click', function() {
    gtag('event', 'click', {
      'event_category': 'test',
      'event_label': 'click_start',
      'value': 'click_start'
    });
  });
  setTimeout(function () {
    gtag('event', 'visitors', {
      'event_category': 'visitors',
      'event_label': 'Pageview',
      'value': location.pathname
    });
  }, 15000);
};

const photoReport = () => {
  $('.js-report-photo').magnificPopup({
    delegate: 'a',
    type: 'image',
    mainClass: 'mfp-no-margins mfp-with-zoom',
    gallery: {
      enabled: true,
      preload: [1,2],
      navigateByImgClick: false,
      tPrev: '',
      tNext: '',
      tCounter: '%curr%/%total%'
    },
    zoom: {
      enabled: false,
      duration: 300
    },
  });
};

$(function () {
  burgerMenu();
  popups();
  animationPage();
  sideScroll();
  share();
  quiz();
  headerFixed();
  eventsGA();
  photoReport();
});
